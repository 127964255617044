<template>
  <AuthComp
    :header="!tokenEmail.nama ? 'Forgot My Password' : 'Hi, ' + tokenEmail.nama"
    :description="
      !tokenEmail.nama
        ? 'We will send you a link to reset your new password. Make sure your email is registered on your account.'
        : 'Please input your new password. Make sure the password is easy to remember.'
    "
  >
    <InputForgot :tokenEmail="tokenEmail" />
  </AuthComp>
</template>
<script>
import InputForgot from '@/components/app/InputForgot'
import { emailForgotToken } from '@/helpers/token.js'
export default {
  name: 'Login',
  components: {
    InputForgot,
    AuthComp: () => import('@/components/app/AuthComp/New'),
  },
  created() {
    if (this.$route.query.token) {
      const tokenEmail = emailForgotToken(this.$route.query.token)
      this.$store.commit('user/SET_STATE', {
        tokenEmail,
      })

      if (tokenEmail) {
        // console.log(tokenEmail)
      }

      this.$router.push({ name: 'Forgot Password' })
    }
  },
  data() {
    return {
      logo: '',
    }
  },
  computed: {
    tokenEmail() {
      return this.$store.state.user.tokenEmail
    },
  },
}
</script>
