<template>
  <div class="forgotPassword">
    <form @submit.prevent="handleSubmit">
      <template v-if="!tokenEmail">
        <!-- <label>Email</label> -->
        <a-input
          v-model="email"
          class="form-input"
          type="text"
          placeholder="Enter your email"
        />
        <!-- <a-row :gutter="[16, 16]">
          <a-col :sm="12" :xs="24">
            <a-button
              :loading="loadingLink"
              size="large"
              html-type="submit"
              class="form-submit"
              >SEND LINK TO EMAIL</a-button
            >
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-button
              size="large"
              @click.prevent="toLogin"
              class="form-submit btn"
              >BACK TO LOGIN</a-button
            >
          </a-col>
        </a-row> -->
        <a-button
          :loading="loadingLink"
          size="large"
          type="primary"
          html-type="submit"
          block
          >Send Link to Email</a-button
        >
        <p @click.prevent="toLogin" class="forgot text-primary mt-3">
          Back to Login
        </p>
      </template>
      <template v-else>
        <!-- <label>Your New Password</label> -->
        <a-input-password
          placeholder="Type your new password"
          v-model="newPassword"
        />
        <div class="mb-4">
          <!-- <small>Minimum 6 characters</small> -->
          <!-- <small v-else class="text-danger">{{errorText.input1}}</small> -->
        </div>
        <!-- <input
          v-model="newPassword"
          class="form-input"
          type="password"
          placeholder="Type your new password"
        /> -->
        <!-- <label>Re-type Your New Password</label> -->
        <a-input-password
          class="mb-5"
          placeholder="Re-type your new password"
          v-model="reTypeNewPassword"
        />
        <!-- <input
          v-model="reTypeNewPassword"
          class="form-input"
          type="text"
          placeholder="Re-type your new password"
        /> -->
        <!-- <div v-if="!isValidate.input2" class="mb-5">
          <small class="text-danger">{{errorText.input2}}</small>
        </div> -->
        <!-- <a-button html-type="submit" size="large" class="form-submit"
          >UPDATE PASSWORD</a-button
        > -->
        <a-button html-type="submit" size="large" type="primary" block
          >Update Password</a-button
        >
      </template>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: '',
      newPassword: '',
      reTypeNewPassword: '',
      isValidate: {
        input1: true,
        input2: true,
      },
      errorText: {
        input1: '',
        input2: '',
      },
      isClicked: false,
      loadingLink: false,
    }
  },
  watch: {
    reTypeNewPassword(newVal) {
      // console.log(newVal)
      if (newVal === this.newPassword || (!this.newPassword && !this.reTypeNewPassword)) {
        this.isValidate.input2 = true
      } else {
        this.errorText.input2 = 'This input must be same with your new password!'
        this.isValidate.input2 = false
      }
    },
  },
  props: ['tokenEmail'],
  methods: {
    toForgot() {
      this.$router.push({ name: 'Forgot Password' })
    },
    toLogin() {
      this.$router.push({ name: 'Login' })
    },
    handleSubmit() {
      if (!this.tokenEmail) {
        this.handleGenerateLink()
      } else {
        this.handleChangePassword()
      }
    },
    async handleGenerateLink() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!this.email || !this.email.match(re)) {
        this.$notification.error({
          message: 'Email is not registered',
          description: 'Please add proper email!',
        })
      } else {
        this.loadingLink = true
        const res = await this.$store.dispatch('user/GENERATE_LINK_FORGOT_PASSWORD', {
          email: this.email,
        })

        if (res.status) {
          this.loadingLink = false
          if (res.message === 'EMAIL_SENT') {
            this.$notification.success({
              message: 'Success Generate Link',
              description: 'Your login link is sent to your email. Please check your email!',
            })
            this.$router.push({ name: 'Login' })
          } else if (res.message === 'EMAIL_NOT_REGISTERED') {
            this.$notification.warning({
              message: 'Warning',
              description: 'Your email has not been registered',
            })
          } else if (res.message === 'EMAIL_NOT_VERIFIED') {
            this.$notification.warning({
              message: 'Warning',
              description: 'Your email has not been verified',
            })
          }
        } else {
          this.loadingLink = false
          this.$notification.error({
            message: 'Sorry.',
            description: 'There should be an Internal Server Error. We are sorry for the inconvenience.',
          })
        }
      }
    },
    async handleChangePassword() {
      this.isClicked = true
      if (!this.newPassword || this.newPassword.length < 6) {
        // console.log()
        this.$notification.error({
          message: 'Minimum 6 Characters!',
          description: 'Your new password must be at least 6 characters.',
        })
      } else if (this.newPassword !== this.reTypeNewPassword || !this.reTypeNewPassword) {
        // console.log('masuk')
        this.$notification.error({
          message: 'Wrong Re-type Password!',
          description: 'Please make sure you re-type same password with your new password!',
        })
      } else {
        const res = await this.$store.dispatch('user/GENERATE_NEW_PASSWORD', {
          password: this.newPassword,
        })

        if (res.status) {
          if (res.message === 'SUCCESS_RESET_PASSWORD') {
            this.$notification.success({
              message: 'Your Password is Updated!',
            })
            this.$store.commit('user/SET_STATE', {
              tokenEmail: '',
            })
          }
          this.$router.push({ name: 'Login' })
        } else {
          this.$notification.error({
            message: 'Sorry.',
            description: 'There should be an Internal Server Error. We are sorry for the inconvenience.',
          })
        }
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.user.isLoading
    },
    isWrong() {
      return this.$store.state.user.isWrong
    },
  },
}
</script>
<style lang="scss">
.forgotPassword {
  label {
    margin-bottom: 10px;
  }

  .forgot {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .form-input {
    margin-bottom: 30px;
    font-size: 16px;
    /* width: 475px; */
    width: 100%;
    height: 48px;
    padding: 10px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-e3eaf7);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e3eaf7;
    border-radius: 4px;
    opacity: 1;
    color: #041930;
  }

  .form-input:focus {
    border-color: var(--kit-color-primary);
  }

  .form-input:hover {
    border-color: var(--kit-color-primary);
  }
  // .form-input {
  //   margin-bottom: 30px;
  //   font-size: 16px;
  //   width: 475px;
  //   height: 48px;
  //   padding: 10px;
  //   background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  //   border: 1px solid var(--unnamed-color-e3eaf7);
  //   background: #ffffff 0% 0% no-repeat padding-box;
  //   border: 1px solid #e3eaf7;
  //   border-radius: 4px;
  //   opacity: 1;
  // }

  // input:focus {
  //   outline: none;
  // }

  // input[type="text"]:focus {
  //   outline: none !important;
  // }

  // input[type="text"] {
  //   outline: none !important;
  // }

  .form-submit {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    padding: 10px 35px;
    // background: #3a9a9b 0% 0% no-repeat padding-box;
    background: var(--kit-color-primary) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    border-style: none;
    color: #ffffff;
  }

  .form-submit:active {
    border-style: none;
  }

  .form-submit:hover {
    opacity: 0.9;
    transition: all 0.2s;
  }

  .ant-input-password {
    .ant-input {
      // margin-bottom: 30px;
      font-size: 16px;
      /* width: 475px; */
      width: 100%;
      height: 48px;
      padding: 10px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      border: 1px solid var(--unnamed-color-e3eaf7);
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e3eaf7;
      border-radius: 4px;
      opacity: 1;
      color: #041930;

      &:focus {
        border-color: var(--kit-color-primary);
      }
    }
    .ant-input-suffix {
      top: 50%;
    }
  }
  .ant-input-affix-wrapper {
    font-size: 18px;
    // color: #041930;
  }
}
</style>
